.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: #20232a;
  padding: 20px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.App-content {
  margin-top: 60px; /* Height of the header */
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 60px); /* Viewport height minus the header height */
}
